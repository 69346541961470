import * as firebase from 'firebase/app'

const config = {"apiKey":"AIzaSyACgvZjvpgEm9oVJXQcTWvpwKHqBpwx-ig","authDomain":"happyly-e3d34.firebaseapp.com","databaseURL":"https:\u002F\u002Fhappyly-e3d34.firebaseio.com","projectId":"happyly-e3d34","storageBucket":"happyly-e3d34.appspot.com","messagingSenderId":"520081218990","appId":"1:520081218990:web:3c0ad83d7612422df37c3f","measurementId":"G-77CEFPV4B4"}

export default async ({ res }, inject) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
  const session = firebase.apps[0]

  /** --------------------------------------------------------------------------------------------- **/
  /** ----------------------------------- FIREBASE ANALYTICS -------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Analytics can only be initiated on the client side
  if (process.client) {
    await import('firebase/analytics')

    const fireAnalytics = session.analytics()
    const fireAnalyticsObj = firebase.analytics
    inject('fireAnalytics', fireAnalytics)
    inject('fireAnalyticsObj', fireAnalyticsObj)
  }
}
