export const PROFILE_LOGOUT = 'PROFILE/LOGOUT'
export const PROFILE_REQUEST = 'PROFILE/REQUEST'
export const PROFILE_SUCCESS = 'PROFILE/SUCCESS'
export const PROFILE_FAILURE = 'PROFILE/FAILURE'
export const PROFILE_FORM_RESET = 'PROFILE_FORM/RESET'
export const PROFILE_FORM_UPDATE = 'PROFILE_FORM/UPDATE'
export const PROFILE_FORM_SUBMIT = 'PROFILE_FORM/SUBMIT'
export const PROFILE_FORM_SUCCESS = 'PROFILE_FORM/SUCCESS'
export const PROFILE_FORM_FAILURE = 'PROFILE_FORM/FAILURE'
export const FAVORITE_REQUEST = 'FAVORITE/REQUEST'
export const FAVORITE_SUCCESS = 'FAVORITE/SUCCESS'
export const FAVORITE_FAILURE = 'FAVORITE/FAILURE'
export const TEAM_REQUEST = 'TEAM/REQUEST'
export const TEAM_SUCCESS = 'TEAM/SUCCESS'
export const TEAM_FAILURE = 'TEAM/FAILURE'
export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION/REQUEST'
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION/SUCCESS'
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION/FAILURE'
export const SEND_VERIFICATION_REQUEST = 'SEND_VERIFICATION/REQUEST'
export const SEND_VERIFICATION_SUCCESS = 'SEND_VERIFICATION/SUCCESS'
export const SEND_VERIFICATION_FAILURE = 'SEND_VERIFICATION/FAILURE'

export const state = () => ({
  profile: {},
  loading: false,
  error: null,
  form: {
    first_name: null,
    last_name: null,
    location: null,
    number_of_kids: 0,
    favorite_activity_types: [],
    kids_ages: [],
    preferred_days: null,
    adventuring_with: null,
    preferred_distance: null,
    preferred_paid_activities: false,
  },
  team: [],
  subscription: null,
  verification: {
    loading: false,
    data: null,
    error: null,
  },
})

export const getters = {
  getProfile(state) {
    return state.profile
  },
}

export const mutations = {
  [PROFILE_REQUEST]: (state) => {
    state.loading = true
  },
  [PROFILE_SUCCESS]: (state, profile) => {
    state.loading = false
    state.profile = profile
    state.error = null
  },
  [PROFILE_FAILURE]: (state, error) => {
    state.loading = false
    state.profile = {}
    state.error = error
  },
  [PROFILE_FORM_UPDATE]: (state, { value, field }) => {
    if (!Object.prototype.hasOwnProperty.call(state.form, field))
      return (state.error = `The ${field} field doesn't match with profile`)

    state.form[field] = value
  },
  [PROFILE_FORM_RESET]: (state, profile) => {
    state.form = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      location: profile.location && profile.location.id,
      number_of_kids: profile.number_of_kids,
      favorite_activity_types: profile.favorite_activity_types,
      kids_ages: profile.kids_ages,
      preferred_days: profile.preferred_days,
      preferred_distance: profile.preferred_distance,
      preferred_paid_activities: profile.preferred_paid_activities,
    }
  },
  [PROFILE_FORM_SUBMIT]: (state) => {
    state.loading = true
    state.error = null
  },
  [PROFILE_FORM_SUCCESS]: (state, profile) => {
    state.loading = false
    state.profile = { ...state.profile, ...profile }
    state.error = null
  },
  [PROFILE_FORM_FAILURE]: (state, error) => {
    state.loading = false
    state.error = error
    state.profile = { ...state.profile }
  },
  [PROFILE_LOGOUT]: (state) => {
    state.form = {}
    state.profile = {}
    state.subscription = null
  },
  [FAVORITE_REQUEST]: (state) => {
    state.loading = true
  },
  [FAVORITE_SUCCESS]: (state, favorites) => {
    state.loading = false
    state.profile.favorites = favorites
    state.error = null
  },
  [FAVORITE_FAILURE]: (state, error) => {
    state.loading = false
    state.error = error
  },
  [TEAM_REQUEST]: (state) => {
    state.loading = true
  },
  [TEAM_SUCCESS]: (state, team) => {
    state.loading = false
    state.team = team
    state.error = null
  },
  [TEAM_FAILURE]: (state, error) => {
    state.loading = false
    state.team = []
    state.error = error
  },
  [SUBSCRIPTION_REQUEST]: (state) => {
    state.loading = true
  },
  [SUBSCRIPTION_SUCCESS]: (state, subscription) => {
    state.loading = false
    state.subscription = subscription
    state.error = null
  },
  [SUBSCRIPTION_FAILURE]: (state, error) => {
    state.loading = false
    state.subscription = null
    state.error = error
  },
  [SEND_VERIFICATION_REQUEST]: (state) => {
    state.verification.loading = true
    state.verification.data = null
    state.verification.error = null
  },
  [SEND_VERIFICATION_SUCCESS]: (state, verification) => {
    state.verification.loading = false
    state.verification.data = verification
    state.verification.error = null
  },
  [SEND_VERIFICATION_FAILURE]: (state, error) => {
    state.verification.loading = false
    state.verification.data = null
    state.verification.error = error
  },
}

export const actions = {
  async [PROFILE_REQUEST]({ commit }, data) {
    commit(PROFILE_REQUEST)
    try {
      this.$axios.setToken(data.token, 'Token')
      const { data: profile } = await this.$axios.get(
        `/api/v2/users/${data.userId}/`
      )
      commit(PROFILE_SUCCESS, profile)
    } catch (error) {
      commit(PROFILE_FAILURE, error.message)
    }
  },
  [PROFILE_FORM_RESET]: ({ commit }, profile) => {
    commit(PROFILE_FORM_RESET, profile)
  },
  async [PROFILE_FORM_SUBMIT]({ commit, rootState }, data) {
    commit(PROFILE_FORM_SUBMIT)
    try {
      const { data: profile } = await this.$axios.patch(
        '/api/v2/users/updateprofile/',
        data
      )
      const cities = rootState.location.availableCities

      profile.location = cities.find((city) => city.id === data.location)

      commit(PROFILE_FORM_SUCCESS, profile)
    } catch (error) {
      commit(PROFILE_FORM_FAILURE, error.message)
    }
  },
  async [FAVORITE_REQUEST]({ commit }, favorite) {
    commit(FAVORITE_REQUEST)
    try {
      const {
        data: { favorites },
      } = await this.$axios.post('/api/v2/favorites/', favorite)

      commit(FAVORITE_SUCCESS, favorites)
    } catch (error) {
      commit(FAVORITE_FAILURE, error.message)
    }
  },
  async [TEAM_REQUEST]({ commit }) {
    commit(TEAM_REQUEST)
    try {
      const { data } = await this.$axios.get('/api/v2/team/')

      commit(TEAM_SUCCESS, data)
    } catch (error) {
      commit(TEAM_FAILURE, error.message)
    }
  },
  async [SUBSCRIPTION_REQUEST]({ commit }, subscriptionId) {
    commit(SUBSCRIPTION_REQUEST)
    try {
      const { data: subscription } = await this.$axios.get(
        `/api/v2/subscriptions/${subscriptionId}/`
      )

      commit(SUBSCRIPTION_SUCCESS, subscription)
    } catch (error) {
      commit(SUBSCRIPTION_FAILURE, error.message)
    }
  },
  async [SEND_VERIFICATION_REQUEST]({ commit }, { phone, login }) {
    commit(SEND_VERIFICATION_REQUEST)
    try {
      const { data: verification } = await this.$axios.post(
        'api/v2/auth/send_verification/',
        {
          phone,
          login,
        }
      )

      commit(SEND_VERIFICATION_SUCCESS, verification)
    } catch (err) {
      const { response, isAxiosError } = err
      commit(
        SEND_VERIFICATION_FAILURE,
        isAxiosError
          ? response.data.message
          : 'Oops! There was an error sending verification code. Please try again.'
      )
    }
  },
}
