import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _158122a4 = () => interopDefault(import('../pages/app-download-mobile.vue' /* webpackChunkName: "pages/app-download-mobile" */))
const _593da56c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _35347c93 = () => interopDefault(import('../pages/cities/index.vue' /* webpackChunkName: "pages/cities/index" */))
const _bc37262c = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _ccac4dca = () => interopDefault(import('../pages/join-your-team.vue' /* webpackChunkName: "pages/join-your-team" */))
const _7e3c52f2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _46543737 = () => interopDefault(import('../pages/mobile-register.vue' /* webpackChunkName: "pages/mobile-register" */))
const _48bf961c = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _ec7fb622 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _0a60c235 = () => interopDefault(import('../pages/welcome-register.vue' /* webpackChunkName: "pages/welcome-register" */))
const _39c0e1f6 = () => interopDefault(import('../pages/subscription/invitation/_token.vue' /* webpackChunkName: "pages/subscription/invitation/_token" */))
const _0502f3d1 = () => interopDefault(import('../pages/activity/_slug.vue' /* webpackChunkName: "pages/activity/_slug" */))
const _57870424 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _337ddb4b = () => interopDefault(import('../pages/cities/_slug.vue' /* webpackChunkName: "pages/cities/_slug" */))
const _2d46cc64 = () => interopDefault(import('../pages/event/_id.vue' /* webpackChunkName: "pages/event/_id" */))
const _ea237570 = () => interopDefault(import('../pages/pages/_slug.vue' /* webpackChunkName: "pages/pages/_slug" */))
const _7d300dd9 = () => interopDefault(import('../pages/authreset/_uid/_token.vue' /* webpackChunkName: "pages/authreset/_uid/_token" */))
const _56069915 = () => interopDefault(import('../pages/reset/_uid/_code.vue' /* webpackChunkName: "pages/reset/_uid/_code" */))
const _96d6a04a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app-download-mobile",
    component: _158122a4,
    name: "app-download-mobile"
  }, {
    path: "/blog",
    component: _593da56c,
    name: "blog"
  }, {
    path: "/cities",
    component: _35347c93,
    name: "cities"
  }, {
    path: "/forgot",
    component: _bc37262c,
    name: "forgot"
  }, {
    path: "/join-your-team",
    component: _ccac4dca,
    name: "join-your-team"
  }, {
    path: "/login",
    component: _7e3c52f2,
    name: "login"
  }, {
    path: "/mobile-register",
    component: _46543737,
    name: "mobile-register"
  }, {
    path: "/profile",
    component: _48bf961c,
    name: "profile"
  }, {
    path: "/search",
    component: _ec7fb622,
    name: "search"
  }, {
    path: "/welcome-register",
    component: _0a60c235,
    name: "welcome-register"
  }, {
    path: "/subscription/invitation/:token?",
    component: _39c0e1f6,
    name: "subscription-invitation-token"
  }, {
    path: "/activity/:slug?",
    component: _0502f3d1,
    name: "activity-slug"
  }, {
    path: "/blog/:slug",
    component: _57870424,
    name: "blog-slug"
  }, {
    path: "/cities/:slug",
    component: _337ddb4b,
    name: "cities-slug"
  }, {
    path: "/event/:id?",
    component: _2d46cc64,
    name: "event-id"
  }, {
    path: "/pages/:slug?",
    component: _ea237570,
    name: "pages-slug"
  }, {
    path: "/authreset/:uid?/:token?",
    component: _7d300dd9,
    name: "authreset-uid-token"
  }, {
    path: "/reset/:uid?/:code?",
    component: _56069915,
    name: "reset-uid-code"
  }, {
    path: "/",
    component: _96d6a04a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
