export const ACTIVITY_TYPES_REQUEST = 'ACTIVITY_TYPES/REQUEST'
export const ACTIVITY_TYPES_SUCCESS = 'ACTIVITY_TYPES/SUCCESS'
export const ACTIVITY_TYPES_FAILURE = 'ACTIVITY_TYPES/FAILURE'
export const ACTIVITY_REQUEST = 'ACTIVITY/REQUEST'
export const ACTIVITY_SUCCESS = 'ACTIVITY/SUCCESS'
export const ACTIVITY_FAILURE = 'ACTIVITY/FAILURE'
export const ACTIVITY_COMPLETE_REQUEST = 'ACTIVITY_COMPLETE/REQUEST'
export const ACTIVITY_COMPLETE_SUCCESS = 'ACTIVITY_COMPLETE/SUCCESS'
export const ACTIVITY_COMPLETE_FAILURE = 'ACTIVITY_COMPLETE/FAILURE'
export const RECOMMENDED_ACTIVITIES_REQUEST = 'RECOMMENDED_ACTIVITIES/REQUEST'
export const RECOMMENDED_ACTIVITIES_SUCCESS = 'RECOMMENDED_ACTIVITIES/SUCCESS'
export const RECOMMENDED_ACTIVITIES_FAILURE = 'RECOMMENDED_ACTIVITIES/FAILURE'
export const LOAD_ACTIVITY_REQUEST = 'LOAD_ACTIVITY/REQUEST'
export const LOAD_ACTIVITY_SUCCESS = 'LOAD_ACTIVITY/SUCCESS'
export const LOAD_ACTIVITY_FAILURE = 'LOAD_ACTIVITY/FAILURE'

export const state = () => ({
  activityList: [],
  activityTypes: [],
  recommendedActivities: [],
  error: null,
  totalActivities: 0,
  loading: false,
  loadingPage: false,
  activity: null,
})

export const mutations = {
  [ACTIVITY_SUCCESS]: (state, activityList) => {
    state.loading = false
    state.loadingPage = false
    state.activityList = activityList
    state.error = null
  },
  [ACTIVITY_FAILURE]: (state, error) => {
    state.loading = false
    state.loadingPage = false
    state.activityList = []
    state.error = error
  },
  [ACTIVITY_REQUEST]: (state, isLoadingPage) => {
    state.loadingPage = !!isLoadingPage
    state.loading = !isLoadingPage
    state.activityList = []
    state.error = null
  },
  [ACTIVITY_TYPES_SUCCESS](state, activityTypes) {
    state.activityTypes = activityTypes
  },

  [RECOMMENDED_ACTIVITIES_REQUEST]: (state) => {
    state.loading = true
    state.recommendedActivities = []
    state.error = null
  },
  [RECOMMENDED_ACTIVITIES_SUCCESS]: (state, recommendedActivities) => {
    state.loading = false
    state.recommendedActivities = recommendedActivities
    state.error = null
  },
  [RECOMMENDED_ACTIVITIES_FAILURE]: (state, error) => {
    state.loading = false
    state.recommendedActivities = []
    state.error = error
  },
  [ACTIVITY_COMPLETE_REQUEST]: (state) => {
    state.loading = true
    state.error = null
  },
  [ACTIVITY_COMPLETE_SUCCESS]: (state, activity) => {
    state.loading = false
    state.activity.completed = activity.completed
  },
  [ACTIVITY_COMPLETE_FAILURE]: (state, error) => {
    state.loading = false
    state.error = error
  },
  [LOAD_ACTIVITY_REQUEST]: (state) => {
    state.loading = true
    state.activity = null
    state.error = null
  },
  [LOAD_ACTIVITY_SUCCESS]: (state, activity) => {
    state.loading = false
    state.activity = activity
    state.error = null
  },
  [LOAD_ACTIVITY_FAILURE]: (state, error) => {
    state.loading = false
    state.error = error
  },
  setTotalActivities(state, total) {
    state.totalActivities = total
  },
}

export const actions = {
  async [ACTIVITY_REQUEST]({ commit }, { params, isLoadingPage = false }) {
    commit(ACTIVITY_REQUEST, isLoadingPage)
    try {
      const {
        data: { results, total },
      } = await this.$axios.get('/api/v2/search/', { params })
      commit(ACTIVITY_SUCCESS, results)
      commit('setTotalActivities', total)
    } catch (error) {
      commit(ACTIVITY_FAILURE, error.message)
    }
  },
  async [ACTIVITY_TYPES_REQUEST]({ commit }) {
    try {
      const {
        data: { results },
      } = await this.$axios.get('/api/v2/activity_types')

      commit(ACTIVITY_TYPES_SUCCESS, results)
    } catch (error) {
      commit(ACTIVITY_TYPES_FAILURE, error.message)
    }
  },
  async [RECOMMENDED_ACTIVITIES_REQUEST]({ commit }) {
    commit(RECOMMENDED_ACTIVITIES_REQUEST)
    try {
      const {
        data: { results },
      } = await this.$axios.get('/api/v2/activity_recommendations/')

      commit(RECOMMENDED_ACTIVITIES_SUCCESS, results)
    } catch (error) {
      commit(RECOMMENDED_ACTIVITIES_FAILURE, error.message)
    }
  },
  async [ACTIVITY_COMPLETE_REQUEST]({ commit }, params) {
    commit(ACTIVITY_COMPLETE_REQUEST)
    try {
      const { data } = await this.$axios.post(
        '/api/v2/activity/completed/',
        params
      )

      commit(ACTIVITY_COMPLETE_SUCCESS, data.activity)
    } catch (error) {
      commit(ACTIVITY_COMPLETE_FAILURE, error.message)
    }
  },
  async [LOAD_ACTIVITY_REQUEST]({ commit }, params) {
    commit(LOAD_ACTIVITY_REQUEST)
    try {
      const { data: results } = await this.$axios.get(
        `/api/v2/activities/${params.id}/`
      )

      commit(LOAD_ACTIVITY_SUCCESS, results)
    } catch (error) {
      commit(LOAD_ACTIVITY_FAILURE, error.message)
    }
  },
}
