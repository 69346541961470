<template>
  <v-container class="navBar super-sticky pa-0">
    <v-card tile>
      <v-toolbar height="85" class="px-6" data-testid="navbar">
        <NavLogo />
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
  </v-container>
</template>

<script>
import NavLogo from './Logo'

export default {
  name: 'Navigation',
  components: {
    NavLogo,
  },
}
</script>

<style lang="scss">
.navBar {
  z-index: 8;
  top: 0;
  max-width: 100% !important;
}
</style>
