<template>
  <v-app id="app">
    <v-main>
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'MobileDefaultPage',
}
</script>
