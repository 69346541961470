<template>
  <a :href="homeLink">
    <v-img :alt="alt" :src="src" :height="height" :width="width" />
  </a>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 80,
      required: false,
    },
    width: {
      type: Number,
      default: 130,
      required: false,
    },
  },
  data: () => ({
    alt: 'Happy.ly logo',
    src: require('../../assets/images/happyly_logo_placeholder.png'),
  }),
  computed: {
    homeLink() {
      return `${process.env.HAPPYLY_URL}/`
    },
  },
}
</script>
