export default function ({ $auth, redirect }) {
  const user = $auth.user
  const subscription = user && user.village_subscription
  const isPremium =
    subscription &&
    (subscription.plan_name === 'Village Premium' ||
      subscription.plan_name === 'Corporate')

  if (!isPremium) return redirect('/')
}
