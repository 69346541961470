const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['invitation'] = require('../middleware/invitation.js')
middleware['invitation'] = middleware['invitation'].default || middleware['invitation']

middleware['premium'] = require('../middleware/premium.js')
middleware['premium'] = middleware['premium'].default || middleware['premium']

export default middleware
