export const SAVED_LIST_REQUEST = 'SAVED_LIST/REQUEST'
export const SAVED_LIST_SUCCESS = 'SAVED_LIST/SUCCESS'
export const SAVED_LIST_FAILURE = 'SAVED_LIST/FAILURE'
export const COMPLETED_LIST_REQUEST = 'COMPLETED_LIST/REQUEST'
export const COMPLETED_LIST_SUCCESS = 'COMPLETED_LIST/SUCCESS'
export const COMPLETED_LIST_FAILURE = 'COMPLETED_LIST/FAILURE'
export const PLANS_LIST_REQUEST = 'PLANS_LIST/REQUEST'
export const PLANS_LIST_SUCCESS = 'PLANS_LIST/SUCCESS'
export const PLANS_LIST_FAILURE = 'PLANS_LIST/FAILURE'
export const RECOMMENDATIONS_LIST_REQUEST = 'RECOMMENDATIONS_LIST/REQUEST'
export const RECOMMENDATIONS_LIST_SUCCESS = 'RECOMMENDATIONS_LIST/SUCCESS'
export const RECOMMENDATIONS_LIST_FAILURE = 'RECOMMENDATIONS_LIST/FAILURE'
export const CREATE_LIST_REQUEST = 'CREATE_LIST/REQUEST'
export const CREATE_LIST_SUCCESS = 'CREATE_LIST/SUCCESS'
export const CREATE_LIST_FAILURE = 'CREATE_LIST/FAILURE'
export const EDIT_LIST_REQUEST = 'EDIT_LIST/REQUEST'
export const EDIT_LIST_SUCCESS = 'EDIT_LIST/SUCCESS'
export const EDIT_LIST_FAILURE = 'EDIT_LIST/FAILURE'
export const DELETE_LIST_REQUEST = 'DELETE_LIST/REQUEST'
export const DELETE_LIST_SUCCESS = 'DELETE_LIST/SUCCESS'
export const DELETE_LIST_FAILURE = 'DELETE_LIST/FAILURE'

export const state = () => ({
  savedLists: {
    data: [],
    error: null,
    loading: false,
  },
  activityCompletedList: {
    data: [],
    error: null,
    loading: false,
  },
  plansList: {
    data: [],
    error: null,
    loading: false,
  },
  recommendationList: {
    data: [],
    error: null,
    loading: false,
  },
  createList: {
    data: null,
    error: null,
    loading: false,
  },
  editList: {
    data: null,
    error: null,
    loading: false,
  },
  deleteList: {
    data: null,
    error: null,
    loading: false,
  },
})

/**
 *
 * @type { import("vuex").GetterTree<ReturnType<typeof state> }
 */
export const getters = {
  getLists: (state) =>
    [
      {
        id: 'my-completed-adventures',
        name: 'My Completed Adventures',
        items: state.activityCompletedList.data.map(
          // eslint-disable-next-line camelcase
          ({ activity, id, date_created }) => ({
            preview_data: activity,
            category: 'activity',
            object_id: id,
            date_created,
          })
        ),
        default: true,
      },
      {
        id: 'my-plans',
        name: 'My Self-Created Adventure Plans',
        items: state.plansList.data.map((data) => ({
          preview_data: data,
          category: 'plan',
          object_id: data.id,
        })),
        default: true,
      },
      {
        id: 'recommendations',
        name: 'happyly Recommendations',
        items: state.recommendationList.data.map((data) => ({
          preview_data: data.plan,
          category: 'plan',
          object_id: data.id,
        })),
        default: true,
      },
    ].concat(state.savedLists.data),
}

/** @type { import("vuex").MutationTree<ReturnType<typeof state>> } */
export const mutations = {
  [SAVED_LIST_REQUEST]: (state) => {
    state.savedLists.data = []
    state.savedLists.error = null
    state.savedLists.loading = true
  },
  [SAVED_LIST_SUCCESS]: (state, lists) => {
    state.savedLists.data = lists
    state.savedLists.error = null
    state.savedLists.loading = false
  },
  [SAVED_LIST_FAILURE]: (state, error) => {
    state.savedLists.error = error
    state.savedLists.loading = false
  },
  [COMPLETED_LIST_REQUEST]: (state) => {
    state.activityCompletedList.data = []
    state.activityCompletedList.error = null
    state.activityCompletedList.loading = true
  },
  [COMPLETED_LIST_SUCCESS]: (state, lists) => {
    state.activityCompletedList.data = lists
    state.activityCompletedList.error = null
    state.activityCompletedList.loading = false
  },
  [COMPLETED_LIST_FAILURE]: (state, error) => {
    state.activityCompletedList.error = error
    state.activityCompletedList.loading = false
  },
  [PLANS_LIST_REQUEST]: (state) => {
    state.plansList.data = []
    state.plansList.error = null
    state.plansList.loading = true
  },
  [PLANS_LIST_SUCCESS]: (state, lists) => {
    state.plansList.data = lists
    state.plansList.error = null
    state.plansList.loading = false
  },
  [PLANS_LIST_FAILURE]: (state, error) => {
    state.plansList.error = error
    state.plansList.loading = false
  },
  [RECOMMENDATIONS_LIST_REQUEST]: (state) => {
    state.recommendationList.data = []
    state.recommendationList.error = null
    state.recommendationList.loading = true
  },
  [RECOMMENDATIONS_LIST_SUCCESS]: (state, lists) => {
    state.recommendationList.data = lists
    state.recommendationList.error = null
    state.recommendationList.loading = false
  },
  [RECOMMENDATIONS_LIST_FAILURE]: (state, error) => {
    state.recommendationList.error = error
    state.recommendationList.loading = false
  },
  [CREATE_LIST_REQUEST]: (state) => {
    state.createList.data = null
    state.createList.error = null
    state.createList.loading = true
  },
  [CREATE_LIST_SUCCESS]: (state, list) => {
    state.createList.data = list
    state.createList.error = null
    state.createList.loading = false
  },
  [CREATE_LIST_FAILURE]: (state, error) => {
    state.createList.error = error
    state.createList.loading = false
  },
  [EDIT_LIST_REQUEST]: (state) => {
    state.editList.data = null
    state.editList.error = null
    state.editList.loading = true
  },
  [EDIT_LIST_SUCCESS]: (state, list) => {
    state.editList.data = list
    state.editList.error = null
    state.editList.loading = false
  },
  [EDIT_LIST_FAILURE]: (state, error) => {
    state.editList.error = error
    state.editList.loading = false
  },
  [DELETE_LIST_REQUEST]: (state) => {
    state.editList.data = null
    state.editList.error = null
    state.editList.loading = true
  },
  [DELETE_LIST_SUCCESS]: (state, list) => {
    state.deleteList.data = list
    state.deleteList.error = null
    state.deleteList.loading = false
  },
  [DELETE_LIST_FAILURE]: (state, error) => {
    state.deleteList.error = error
    state.deleteList.loading = false
  },
  clearCreateList(state) {
    state.createList.data = null
    state.createList.error = null
    state.createList.loading = false
    state.editList.data = null
    state.editList.error = null
    state.editList.loading = false
  },
}

/**
 * @type { import("vuex").ActionTree<ReturnType<typeof state>>}
 */
export const actions = {
  async [SAVED_LIST_REQUEST]({ commit }) {
    commit(SAVED_LIST_REQUEST)

    try {
      const {
        data: { results },
      } = await this.$axios.get('/api/v2/saved_lists/')

      commit(SAVED_LIST_SUCCESS, results)
    } catch (error) {
      commit(SAVED_LIST_FAILURE, error.message)
    }
  },
  async [COMPLETED_LIST_REQUEST]({ commit }) {
    commit(COMPLETED_LIST_REQUEST)

    try {
      const {
        data: { results },
      } = await this.$axios.get('/api/v2/activity/completed/')

      commit(COMPLETED_LIST_SUCCESS, results)
    } catch (error) {
      commit(COMPLETED_LIST_FAILURE, error.message)
    }
  },
  async [PLANS_LIST_REQUEST]({ commit }) {
    commit(PLANS_LIST_REQUEST)

    try {
      const {
        data: { results },
      } = await this.$axios.get('/api/v2/plans/')

      commit(PLANS_LIST_SUCCESS, results)
    } catch (error) {
      commit(PLANS_LIST_FAILURE, error.message)
    }
  },
  async [RECOMMENDATIONS_LIST_REQUEST]({ commit }) {
    commit(RECOMMENDATIONS_LIST_REQUEST)

    try {
      const {
        data: { results },
      } = await this.$axios.get('/api/v2/plan_recommendations/')

      commit(RECOMMENDATIONS_LIST_SUCCESS, results)
    } catch (error) {
      commit(RECOMMENDATIONS_LIST_FAILURE, error.message)
    }
  },
  async getLists({ dispatch }) {
    await dispatch(COMPLETED_LIST_REQUEST)
    await dispatch(PLANS_LIST_REQUEST)
    await dispatch(RECOMMENDATIONS_LIST_REQUEST)
    await dispatch(SAVED_LIST_REQUEST)
  },
  async [CREATE_LIST_REQUEST]({ commit }, payload) {
    commit(CREATE_LIST_REQUEST)

    try {
      const { data } = await this.$axios.post('/api/v2/saved_lists/', payload)

      commit(CREATE_LIST_SUCCESS, data.id)
    } catch (error) {
      commit(CREATE_LIST_FAILURE, error.message)
    }
  },
  async [EDIT_LIST_REQUEST]({ commit }, payload) {
    commit(EDIT_LIST_REQUEST)

    try {
      const { data } = await this.$axios.put(
        `/api/v2/saved_lists/${payload.id}/`,
        payload
      )

      commit(EDIT_LIST_SUCCESS, data.id)
    } catch (error) {
      commit(EDIT_LIST_FAILURE, error.message)
    }
  },
  async [DELETE_LIST_REQUEST]({ commit }, payload) {
    commit(DELETE_LIST_REQUEST)

    try {
      const { data } = await this.$axios.delete(
        `/api/v2/saved_lists/${payload.id}`
      )

      commit(DELETE_LIST_SUCCESS, data.id)
    } catch (error) {
      commit(DELETE_LIST_FAILURE, error.message)
    }
  },
}
