export const GEOLOCATION_POSITION_REQUEST = 'GEOLOCATION_POSITION/REQUEST'
export const GEOLOCATION_POSITION_SUCCESS = 'GEOLOCATION_POSITION/SUCCESS'
export const GEOLOCATION_POSITION_FAILURE = 'GEOLOCATION_POSITION/FAILURE'
export const CITIES_REQUEST = 'CITIES/REQUEST'
export const CITIES_SUCCESS = 'CITIES/SUCCESS'
export const CITIES_FAILURE = 'CITIES/FAILURE'

export const state = () => ({
  availableCities: [],
  cityLocation: null,
  geolocationPosition: null,
  navigatorPosition: null,
  neighborhoods: [],
  error: null,
  loading: false,
})

export const getters = {
  hasUserLocation(state) {
    return state.geolocationPosition || state.navigatorPosition
  },
  userLocation(state) {
    if (state.navigatorPosition) return state.navigatorPosition
    if (state.geolocationPosition) return state.geolocationPosition

    return null
  },
  cityLocation(state) {
    return state.cityLocation
  },
}

export const mutations = {
  [GEOLOCATION_POSITION_REQUEST]: (state) => {
    state.geolocationPosition = null
    state.error = null
  },
  [GEOLOCATION_POSITION_SUCCESS]: (state, position) => {
    state.geolocationPosition = position
    state.error = null
  },
  [GEOLOCATION_POSITION_FAILURE]: (state, error) => {
    state.geolocationPosition = null
    state.error = error
  },
  [CITIES_REQUEST]: (state) => {
    state.loading = true
    state.availableCities = []
    state.error = null
  },
  [CITIES_SUCCESS]: (state, cities) => {
    state.loading = false
    state.availableCities = [
      {
        name: 'My current location',
        slug: 'my-current-location',
        id: 'nearby',
      },
    ].concat(cities)
    state.error = null
  },
  [CITIES_FAILURE]: (state, error) => {
    state.loading = false
    state.availableCities = []
    state.error = error
  },
  setCityLocation(state, city) {
    state.cityLocation = city
  },
  setNavigatorPosition(state, navigatorPosition) {
    state.navigatorPosition = navigatorPosition
  },
  setNeighborhoods(state, neighborhoods) {
    state.neighborhoods = neighborhoods
  },
}

export const actions = {
  async [GEOLOCATION_POSITION_REQUEST]({ commit }) {
    commit(GEOLOCATION_POSITION_REQUEST)
    try {
      const { data } = await this.$axios.get('/api/v2/ipLookup')

      if (data.status === 'success') {
        commit(GEOLOCATION_POSITION_SUCCESS, {
          isp: data.isp,
          latitude: data.lat,
          longitude: data.lon,
          city: data.city,
          country: data.country,
          zip: data.zip,
        })
      } else {
        throw new Error(`${data.msg} ${data.ip}`)
      }
    } catch (error) {
      commit(GEOLOCATION_POSITION_FAILURE, error.message)
    }
  },
  async [CITIES_REQUEST]({ commit }) {
    commit(CITIES_REQUEST)
    try {
      const { data } = await this.$axios.get('/api/v2/cities/')

      commit(CITIES_SUCCESS, data)
    } catch (error) {
      commit(CITIES_FAILURE, error.message)
    }
  },
}
