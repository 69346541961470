<template>
  <v-app id="app">
    <Navigation />
    <v-main>
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from '@/components/navigation'

export default {
  name: 'DefaultPage',
  components: { Navigation },
  async created() {
    if (process.browser) {
      const BranchSDK = (await import('branch-sdk')).default

      BranchSDK.init(this.$config.branchIOApiKey, {}, (error) => {
        if (typeof error !== 'undefined' && error !== null)
          this.$sentry.captureMessage(error)
      })
    }
  },
}
</script>

<style lang="scss">
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.v-input__control {
  border-radius: 0 !important;
}

/* Fix dark button when is disabled */
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #f8f8f8 !important;
  color: #aaa !important;
}

/* Position Sticky Default */
.super-sticky {
  position: sticky !important;
}

a {
  text-decoration: none;
}
</style>
