export const STEP_SET = 'STEP/SET'
export const STEP_FLAG_SET = 'STEP_FLAG/SET'
export const STEP_VALUE_SET = 'STEP_VALUE/SET'

export const state = () => ({
  activeStep: 1,
  stepFlag: false,
  stepValue: 1,
  dialogOpen: false,
})

export const getters = {
  getActiveStep(state) {
    return state.activeStep
  },
  getStepFlag(state) {
    return state.stepFlag
  },
  getStepValue(state) {
    return state.stepValue
  },
}

export const mutations = {
  [STEP_SET]: (state, step) => {
    state.activeStep = step
  },
  [STEP_FLAG_SET]: (state, flag) => {
    state.stepFlag = flag
  },
  [STEP_VALUE_SET]: (state, step) => {
    state.stepValue = step
  },
}

export const actions = {
  [STEP_SET]({ commit }, step) {
    commit(STEP_SET, step)
  },
  [STEP_FLAG_SET]({ commit }, flag) {
    commit(STEP_FLAG_SET, flag)
  },
  [STEP_VALUE_SET]({ commit }, step) {
    commit(STEP_VALUE_SET, step)
  },
}
