import axios from 'axios'
export const BLOGS_REQUEST = 'BLOGS/REQUEST'
export const BLOGS_SUCCESS = 'BLOGS/SUCCESS'
export const BLOGS_FAILURE = 'BLOGS/FAILURE'
export const LATEST_BLOGS_REQUEST = 'LATEST_BLOGS/REQUEST'
export const LATEST_BLOGS_SUCCESS = 'LATEST_BLOGS/SUCCESS'
export const LATEST_BLOGS_FAILURE = 'LATEST_BLOGS/FAILURE'

const axiosSearchInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 3000,
})

export const state = () => ({
  blogs: [],
  latestBlogs: [],
  total: 0,
  error: null,
  loading: false,
})

export const mutations = {
  [BLOGS_REQUEST]: (state) => {
    state.loading = true
    state.blogs = []
    state.total = 0
    state.error = null
  },
  [BLOGS_SUCCESS]: (state, { data, total }) => {
    state.loading = false
    state.blogs = data
    state.total = total
    state.error = null
  },
  [BLOGS_FAILURE]: (state, error) => {
    state.loading = false
    state.blogs = []
    state.total = 0
    state.error = error
  },
  [LATEST_BLOGS_REQUEST]: (state) => {
    state.loading = true
    state.latestBlogs = []
    state.error = null
  },
  [LATEST_BLOGS_SUCCESS]: (state, latestBlogs) => {
    state.loading = false
    state.latestBlogs = latestBlogs
    state.error = null
  },
  [LATEST_BLOGS_FAILURE]: (state, error) => {
    state.loading = false
    state.latestBlogs = []
    state.error = error
  },
}

export const actions = {
  async [BLOGS_REQUEST]({ commit }, params) {
    commit(BLOGS_REQUEST)
    try {
      const {
        data: { data, total },
      } = await axiosSearchInstance.post(`/backend/search/blogs`, params)

      commit(BLOGS_SUCCESS, { data, total })
    } catch (error) {
      commit(BLOGS_FAILURE, error.message)
    }
  },
  async [LATEST_BLOGS_REQUEST]({ commit }, params) {
    commit(LATEST_BLOGS_REQUEST)
    try {
      const {
        data: { data },
      } = await axiosSearchInstance.post(`/backend/search/blogs`, params)

      commit(LATEST_BLOGS_SUCCESS, data)
    } catch (error) {
      commit(LATEST_BLOGS_FAILURE, error.message)
    }
  },
}
